<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-avatar>
      <div
        class="text-xs-center"
      >
        <v-avatar
          :color="formattedItem.avatarColor"
        >
          <span class="white--text font-weight-bold subtitle-1">{{ formattedItem.avatar }}</span>
        </v-avatar>
      </div>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle>
        <v-chip
          v-if="formattedItem.conTiempoTrabajado"
          color="red"
          dark
          label
          small
          class="mt-1 mr-1"
        >
          Con tiempo trabajado
        </v-chip>
        <v-chip
          v-if="formattedItem.parte_trabajo_tecnico.principal"
          color="green"
          dark
          label
          small
          class="mt-1"
        >
          Principal
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.empleado.nombre_y_apellido
      item.subtitle = ''
      item.avatarColor = 'info'
      item.avatar = item.empleado.codigo
      return item
    }
  }
}
</script>
